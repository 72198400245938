<template>
    <Box>
  
      <DetailsDisplayRow v-if="this.showDialog === 'detailsDialog'" :timesheetId="this.selectedTimesheetId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></DetailsDisplayRow>
      <WorkingDisplayRow v-if="this.showDialog === 'workingDialog'"></WorkingDisplayRow>

      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import DetailsDisplayRow from './details/DetailsDisplayRow.vue';
import WorkingDisplayRow from '../../../../../shared/views/working/WorkingDisplayRow.vue';

export default {
    name: "timesheets-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      DetailsDisplayRow,
      WorkingDisplayRow,
    },
    props: {
      adminDisplay: { type: Boolean, default: false },
      isAdmin: {type: Boolean, default: false},
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        footerData: [],
        headerData: [],
        showMissingRequirements: false,
        showDialog: null,
        showEmail: false,
        showRemarks: false,
        selectedTimesheetId: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from) {
        if (to && from) {
          if (to.query["action"] === "timesheetDetails") {
            this.selectedTimesheetId = to.query["timesheetId"];
            this.showDialog = "detailsDialog";

          } else if (to.query["action"] === "email") {
            this.selectedTimesheetId = to.query["timesheetId"];
            this.showDialog = "emailDialog";

          } else if (to.query["action"] === "remarks") {
            this.selectedTimesheetId = to.query["timesheetId"];
            this.showDialog = "remarksDialog";

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            
            delete toQuery.action;
            delete toQuery.timesheetId;

            delete fromQuery.action;
            delete fromQuery.timesheetId;

            if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = ""; 
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      },
    },
    mounted: function () {
      this.viewModel = new this.ListViewModel(this);
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        ConnectionUtils.ensureCompanyLoaded(this, "hours");
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadTimesheets();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDetailsIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"] === "timesheetDetails") {
          this.selectedTimesheetId = query["timesheetId"];
          this.showDialog = "detailsDialog";
        } 
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row != null) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      withValue: function(valueData) {
        this.model().withValue(valueData);
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "timesheet");
          this.addQueryParams(valueData, "pageSize");

        } else if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");

        } else if (valueData["field"] === "Search") {
          this.addQueryParams(valueData, "search");

        } else if (valueData["field"] === "Date") {
          this.addQueryParams(valueData, "time")

        } else if (valueData["field"] === "User") {
          const value = valueData["value"];
          if (value && value != "null") {
            valueData["value"] = this.Contents.baseEncode(value);
            this.addQueryParams(valueData, "userId")
          } else {
            this.addQueryParams(valueData, "userId")
          }

        } else {
          this.$emit("withValue", valueData);
        }
      },
      
      addQueryParams(valueData, key) {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },

      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },

      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);
        this.log().info("Operator: {0}", [JSON.stringify(buttonData, null, 2)]);

        if (buttonData["field"] === "Details") {
          const query = Object.assign({}, this.$route.query);
          query["action"] = "timesheetDetails";
          query["timesheetId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Refresh") {
          this.reloadPage();

        } else if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "DetailsClose" || buttonData["field"] === "Close") {
          var query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Time") {
          this.addQueryParams(buttonData, "time");

        } else if (buttonData["field"] === "Status") {
          this.addQueryParams(buttonData, "status");

        } else if (buttonData["field"] === "Full") {
          var fullParams = {
            id: buttonData["id"],
          };
          this.$router.push({
            name: ConstUtils.ROUTES.TIMESHEET.DETAILS,
            params: fullParams,
          });
        } else {
          this.$emit("withButtonPressed", buttonData);
        }
      },
      
      log() {
        return this.domain.logger();
      },

      deleteListener: function(response) {
        this.showDialog = "workingDialog";
        if (response.hasError()) {
          //
        } else {
          this.reloadPage();
        }
      },

      deleteDetails(query) {
        delete query.action;
        delete query.timesheetId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadTimesheets();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>